import 'react-datepicker/dist/react-datepicker.css';

import { DateFormatOptions, DateUtils, LocaleUtils } from '@wyz/utils';
import { Locale } from 'date-fns';
import * as React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Button, Flex } from '../index';

export type DateTimePickerProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
> = Pick<
  ReactDatePickerProps<CustomModifierNames, WithRange>,
  | 'placeholderText'
  | 'onChange'
  | 'selected'
  | 'showTimeSelect'
  | 'selectsRange'
  | 'startDate'
  | 'endDate'
  | 'formatWeekDay'
  | 'monthsShown'
  | 'minDate'
  | 'disabled'
> & {
  id?: string;
  name: string;
  helperText?: string;
  label?: string;
  readOnly?: boolean;
  required?: boolean;
  translations?: Partial<{
    resetButton: string;
    applyButton: string;
    todayButton: string;
  }>;
  locale: string;
  dateFormat?: DateFormatOptions['format'];
};

type DatePickRefApi = {
  readonly setOpen: (open: boolean, skipSetBlur?: boolean) => void;
  readonly clear: () => void;
};
function DatePicker<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined,
>({ locale, ...props }: DateTimePickerProps<CustomModifierNames, WithRange>) {
  const calRef = React.useRef(null);
  const [localeModule, setLocaleModule] = React.useState<Locale>();
  const DatePickeRef = calRef.current as unknown as DatePickRefApi | null;
  React.useLayoutEffect(() => {
    // lazy load the locale module
    LocaleUtils.getLocaleModule(locale)
      .then((module) => {
        setLocaleModule(module);
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.error(`Failed to load locale data for date-fns for ${locale}`);
      });
  }, [locale]);
  return (
    <ReactDatePicker<CustomModifierNames, WithRange>
      ref={calRef}
      showTimeSelect
      {...props}
      placeholderText={
        props.dateFormat
          ? DateUtils.getFormatPattern(props.dateFormat)
          : 'dd-MM-yyyy h:mm aa'
      }
      dateFormat={
        props.dateFormat
          ? DateUtils.getFormatPattern(props.dateFormat)
          : 'dd-MM-yyyy h:mm aa'
      }
      className='form-control'
      autoComplete='off'
      timeIntervals={5}
      shouldCloseOnSelect={false}
      fixedHeight
      selected={props.selected}
      onChange={props.onChange}
      locale={localeModule}
    >
      <div>
        <Flex
          alignItems='center'
          justifyContent='end'
          className='py-2 space-x-4'
        >
          <Button
            id='reset-datepicker'
            variant='secondary'
            onClick={() => {
              DatePickeRef?.clear();
              DatePickeRef?.setOpen(false);
            }}
          >
            {props.translations?.resetButton || 'Reset'}
          </Button>
          <Button
            id='apply-datepicker'
            variant='primary'
            onClick={() => {
              DatePickeRef?.setOpen(false);
            }}
          >
            {props.translations?.applyButton || 'Apply'}
          </Button>
        </Flex>
      </div>
    </ReactDatePicker>
  );
}

export default DatePicker;
