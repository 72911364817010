export const TodayDeliveryIcon = ({
  ref,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  ref?: React.RefObject<SVGSVGElement>;
}) => (
  <svg
    width='44'
    height='30'
    viewBox='0 0 44 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.8164 5.27246C16.3569 5.29308 20.1957 8.60004 21.4551 13.1345L11.8164 16.1661V5.27246Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.5934 18.9521L14.5447 24.6141H15.9029L16.9515 18.9521H18.791L19.0317 17.668H13.9086L13.668 18.9521H15.5934Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.7734 21.7056C18.7734 22.6979 19.0098 23.4567 19.4826 23.9821C19.9553 24.5074 20.6272 24.7701 21.4983 24.7701C22.0369 24.7701 22.514 24.655 22.9294 24.4247C23.3449 24.1945 23.6944 23.8816 23.9781 23.4859C24.2617 23.0903 24.4781 22.6331 24.6271 22.1142C24.7761 21.5954 24.8505 21.0506 24.8505 20.4798C24.8505 19.9934 24.7861 19.5654 24.6571 19.1957C24.5282 18.826 24.3463 18.5147 24.1113 18.2617C23.8764 18.0088 23.5898 17.8191 23.2518 17.6926C22.9137 17.5662 22.5383 17.5029 22.1257 17.5029C21.61 17.5029 21.1444 17.6164 20.7289 17.8434C20.3135 18.0704 19.961 18.3769 19.6717 18.7628C19.3823 19.1487 19.1602 19.5945 19.0055 20.1004C18.8508 20.6063 18.7734 21.1414 18.7734 21.7056ZM20.1229 21.7056C20.1229 21.3748 20.163 21.036 20.2433 20.689C20.3235 20.342 20.4453 20.0275 20.6086 19.7453C20.7719 19.4632 20.9811 19.233 21.2361 19.0546C21.4911 18.8763 21.7905 18.7871 22.1343 18.7871C22.3865 18.7871 22.5985 18.8357 22.7704 18.933C22.9423 19.0303 23.0827 19.16 23.1916 19.3222C23.3005 19.4843 23.3793 19.674 23.428 19.8913C23.4767 20.1085 23.501 20.3404 23.501 20.5869C23.501 20.9176 23.4595 21.2549 23.3764 21.5986C23.2933 21.9423 23.1687 22.2553 23.0025 22.5374C22.8363 22.8195 22.63 23.0498 22.3836 23.2281C22.1372 23.4065 21.8478 23.4957 21.5154 23.4957C21.269 23.4957 21.0584 23.447 20.8837 23.3497C20.7089 23.2525 20.5656 23.1211 20.4539 22.9557C20.3421 22.7903 20.259 22.6006 20.2046 22.3866C20.1502 22.1726 20.1229 21.9456 20.1229 21.7056Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.6988 23.3299L27.524 18.9521H28.5382C28.7789 18.9521 28.9838 18.9927 29.1528 19.0737C29.3219 19.1548 29.4608 19.2699 29.5697 19.4191C29.6786 19.5683 29.7574 19.745 29.8061 19.9493C29.8548 20.1536 29.8792 20.3822 29.8792 20.6351C29.8792 21.0048 29.8319 21.3518 29.7373 21.6761C29.6428 22.0004 29.5053 22.2857 29.3247 22.5322C29.1442 22.7786 28.9207 22.9732 28.6543 23.1159C28.3878 23.2586 28.0827 23.3299 27.7389 23.3299H26.6988ZM26.4065 17.668L25.1172 24.6141H27.6787C28.2689 24.6141 28.7861 24.5071 29.2302 24.2931C29.6743 24.079 30.0439 23.7823 30.339 23.4029C30.6341 23.0235 30.8562 22.5744 31.0052 22.0555C31.1542 21.5367 31.2287 20.9757 31.2287 20.3725C31.2287 19.5099 31.0109 18.8435 30.5754 18.3733C30.1399 17.9031 29.4895 17.668 28.6242 17.668H26.4065Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34.4781 17.668L30.8594 24.6141H32.2777L33.0513 23.0673H35.3463L35.544 24.6141H36.8849L35.862 17.668H34.4781ZM33.5842 21.929L34.9079 19.1661H34.9251L35.226 21.929H33.5842Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M38.7589 21.9193L38.269 24.6141H39.6271L40.0998 21.9874L43.0911 17.668H41.5009L39.5927 20.6254L38.673 17.668H37.2891L38.7589 21.9193Z'
      fill='currentColor'
    />
    <path
      d='M21.4314 28.9897V26.7468H21.9405C22.1163 26.7468 22.264 26.7742 22.3837 26.829C22.5034 26.8839 22.5996 26.9628 22.6723 27.0658C22.7451 27.1688 22.7973 27.2926 22.8292 27.4371C22.861 27.5816 22.8769 27.7437 22.8769 27.9231C22.8769 28.1191 22.8542 28.2853 22.8087 28.4215C22.7632 28.5577 22.7026 28.6682 22.6269 28.753C22.5511 28.8377 22.4648 28.8983 22.3678 28.9349C22.2708 28.9714 22.1708 28.9897 22.0678 28.9897H21.4314ZM20.7178 26.0889V29.6476H22.1178C22.3663 29.6476 22.5822 29.6019 22.7655 29.5106C22.9489 29.4192 23.1019 29.2938 23.2246 29.1343C23.3473 28.9748 23.439 28.7854 23.4996 28.5661C23.5602 28.3467 23.5905 28.1075 23.5905 27.8483C23.5905 27.5526 23.5534 27.295 23.4792 27.0758C23.4049 26.8565 23.3019 26.6737 23.1701 26.5275C23.0383 26.3813 22.8822 26.2716 22.7019 26.1985C22.5216 26.1254 22.3269 26.0889 22.1178 26.0889H20.7178Z'
      fill='currentColor'
    />
    <path
      d='M24.0859 26.0889V29.6476H26.5496V28.9897H24.7996V28.1175H26.3723V27.5094H24.7996V26.7468H26.5132V26.0889H24.0859Z'
      fill='currentColor'
    />
    <path
      d='M27.0312 26.0889V29.6476H29.3267V28.9897H27.7449V26.0889H27.0312Z'
      fill='currentColor'
    />
    <path
      d='M30.4402 26.0889H29.7266V29.6476H30.4402V26.0889Z'
      fill='currentColor'
    />
    <path
      d='M32.5772 29.6476L33.6545 26.0889H32.9181L32.1863 28.591H32.1772L31.4545 26.0889H30.7227L31.7727 29.6476H32.5772Z'
      fill='currentColor'
    />
    <path
      d='M33.9316 26.0889V29.6476H36.3953V28.9897H34.6453V28.1175H36.218V27.5094H34.6453V26.7468H36.359V26.0889H33.9316Z'
      fill='currentColor'
    />
    <path
      d='M37.5906 27.6988V26.6969H38.3724C38.5361 26.6969 38.6588 26.736 38.7406 26.8141C38.8224 26.8922 38.8633 27.0176 38.8633 27.1904C38.8633 27.3698 38.8224 27.4994 38.7406 27.5792C38.6588 27.6589 38.5361 27.6988 38.3724 27.6988H37.5906ZM36.877 26.0889V29.6476H37.5906V28.257H38.3042C38.483 28.257 38.6118 28.3002 38.6906 28.3866C38.7694 28.473 38.8209 28.6093 38.8452 28.7953C38.8633 28.9382 38.877 29.0877 38.8861 29.2439C38.8952 29.4001 38.9194 29.5347 38.9588 29.6476H39.6724C39.6391 29.5978 39.6141 29.5372 39.5974 29.4657C39.5808 29.3943 39.5686 29.3187 39.5611 29.2389C39.5535 29.1592 39.5482 29.0811 39.5452 29.0047C39.5421 28.9282 39.5391 28.8618 39.5361 28.8053C39.53 28.7156 39.5187 28.6259 39.502 28.5362C39.4853 28.4464 39.4588 28.3642 39.4224 28.2894C39.3861 28.2147 39.3391 28.1499 39.2815 28.095C39.224 28.0402 39.1512 27.9995 39.0634 27.9729V27.963C39.2452 27.8832 39.3762 27.7669 39.4565 27.6141C39.5368 27.4612 39.577 27.2801 39.577 27.0708C39.577 26.9345 39.555 26.8074 39.5111 26.6895C39.4671 26.5715 39.4035 26.4677 39.3202 26.378C39.2368 26.2882 39.1368 26.2176 39.0202 26.1661C38.9035 26.1146 38.7724 26.0889 38.627 26.0889H36.877Z'
      fill='currentColor'
    />
    <path
      d='M40.8315 28.262V29.6476H41.5452V28.2819L42.7497 26.0889H41.9543L41.1997 27.4944L40.4406 26.0889H39.6406L40.8315 28.262Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.443 25.5505C17.7983 25.2857 18.1603 25.4738 18.4018 25.8634C18.6432 26.2529 18.6732 26.6973 18.318 26.962C16.4492 28.3548 14.1936 29.1693 11.7658 29.1693C5.3222 29.1693 0.0908203 23.4329 0.0908203 16.3674C0.0908203 9.30179 5.3222 3.56543 11.7658 3.56543C18.2094 3.56543 23.4407 9.30179 23.4407 16.3674C23.4407 16.8383 23.0776 17.1079 22.6481 17.1079C22.2186 17.1079 21.8842 16.8383 21.8842 16.3674C21.8842 10.2438 17.3503 5.27217 11.7658 5.27217C6.18124 5.27217 1.64731 10.2438 1.64731 16.3674C1.64731 22.491 6.18124 27.4626 11.7658 27.4626C13.8693 27.4626 15.8239 26.7573 17.443 25.5505Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.70936 3.20854C8.06509 3.20854 7.54199 2.63498 7.54199 1.92849C7.54199 1.222 8.06509 0.648438 8.70936 0.648438H14.5981C15.2424 0.648438 15.7655 1.222 15.7655 1.92849C15.7655 2.63498 15.2424 3.20854 14.5981 3.20854H8.70936Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.1946 5.73311C18.832 5.29886 18.8592 4.62355 19.2552 4.22602C19.6512 3.82847 20.2671 3.85825 20.6296 4.29249L23.8617 8.16367C24.2242 8.59791 24.197 9.27322 23.801 9.67076C23.405 10.0683 22.7892 10.0385 22.4266 9.60428L19.1946 5.73311Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.9667 8.91702C17.1802 8.65243 17.5494 8.62766 17.7907 8.86172C18.0319 9.09576 18.0545 9.50057 17.8411 9.76515L12.3196 16.6094C12.1061 16.874 11.737 16.8987 11.4957 16.6647C11.2544 16.4306 11.2318 16.0258 11.4452 15.7612L16.9667 8.91702Z'
      fill='currentColor'
    />
    <path
      d='M11.8267 17.2231C12.3641 17.2231 12.7998 16.7453 12.7998 16.156C12.7998 15.5666 12.3641 15.0889 11.8267 15.0889C11.2892 15.0889 10.8535 15.5666 10.8535 16.156C10.8535 16.7453 11.2892 17.2231 11.8267 17.2231Z'
      fill='currentColor'
    />
  </svg>
);
