import * as React from 'react';
import * as ReactDom from 'react-dom';
const TOP_NAVBAR_PORTAL_ID = 'menu-portal';
export const TopNavbarPortal: React.FC = () => {
  return <div id={TOP_NAVBAR_PORTAL_ID} className='w-100'></div>;
};

export const DynamicTopNavbarPortal: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const element = document.getElementById(TOP_NAVBAR_PORTAL_ID);
  if (!element) {
    return <></>;
  }
  return ReactDom.createPortal(children, element);
};
