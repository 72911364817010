import { DateUtils } from '@wyz/utils';
import {
  DateField,
  DateRangeField,
  SelectField,
  TypeaheadField,
} from '../../index';
import { FilterDataSource } from '../types';
type Props = {
  label: string;
  name: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
} & FilterDataSource;

const t = (key: string) => key;
const FILTERS = {
  LIST: (props) => {
    if (props.type !== 'LIST') {
      throw new Error();
    }
    return (
      <SelectField
        {...props}
        withCheckboxes
        translate={t}
        placeholder={props.placeholder}
      />
    );
  },
  TYPEAHEAD: (props) => {
    if (props.type !== 'TYPEAHEAD') {
      throw new Error();
    }
    return <TypeaheadField {...props} translate={t} isMulti={props.isMulti} />;
  },
  DATE: (props) => {
    if (props.type !== 'DATE') {
      return <></>;
    }
    return (
      <DateField
        {...props}
        translate={t}
        dateFormat={DateUtils.DATE_FORMAT.FULL_DATE}
        placeholderText={props.placeholder}
        showTimeSelect={false}
      />
    );
  },
  DATE_RANGE: (props) => {
    if (props.type !== 'DATE_RANGE') {
      return <></>;
    }
    return (
      <DateRangeField
        {...props}
        translate={t}
        dateFormat={DateUtils.DATE_FORMAT.FULL_DATE}
        placeholderText={props.placeholder ?? 'dd-MM-yyyy'}
        showTimeSelect={false}
      />
    );
  },
} satisfies Record<string, React.FC<Props>>;

export default FILTERS;
