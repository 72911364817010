import { SideBarRoute } from '~/common/types';
import { TranslationKeys } from '~/common/types';

export const dashboardRoutes: SideBarRoute<keyof TranslationKeys> = {
  label: 'dashboard',
  children: [
    {
      name: 'app.common.sidebar.home',
      icon: 'home',
      to: '/app/settings',
      exact: true,

      dataCy: 'home',
    },
    {
      name: 'app.common.sidebar.particular_orders',
      to: '#',
      icon: 'customer-service',
      dataCy: 'management',
      children: [
        {
          name: 'app.common.sidebar.ean_regularization',
          to: '/app/orders/ean-regularization',
          dataCy: 'ean_regularization',
          exact: true,
        },
        {
          name: 'app.common.sidebar.quantity_regularization',
          to: '/app/orders/quantity-regularization',
          dataCy: 'quantity_regularization',
          exact: true,
        },
        {
          name: 'app.common.sidebar.substitution',
          to: '/app/orders/substitution',
          dataCy: 'substitution',
          exact: true,
        },
        {
          name: 'app.common.sidebar.refusal_take_back',
          to: '/app/orders/refusal-take-back',
          dataCy: 'refusal_take_back',
          exact: true,
        },
        {
          name: 'app.common.sidebar.orders.update',
          to: '/app/orders',
          dataCy: 'order_update',
          exact: true,
        },
        {
          name: 'app.common.sidebar.zero_order',
          to: '/app/orders/zero-order',
          dataCy: 'zero_order',
          exact: true,
        },
        {
          name: 'app.common.sidebar.blank_regularization',
          to: '/app/orders/zero-regularization',
          dataCy: 'zero_regularization',
          exact: true,
          externalTarget: true,
        },
        {
          name: 'app.common.sidebar.bulk_order',
          to: '/app/orders/bulk-order',
          dataCy: 'bulk_order',
          exact: true,
          externalTarget: true,
        },
      ],
    },
    {
      name: 'app.common.sidebar.management',
      to: '#',

      icon: 'cogs',
      dataCy: 'management',
      children: [
        {
          name: 'app.common.sidebar.automaker_groups',
          icon: 'cogs',
          to: '/app/settings/automaker-groups',
          dataCy: 'automakerGroups',
          exact: true,
        },
        {
          name: 'app.common.sidebar.translations',
          icon: 'cogs',
          to: '/app/settings/translations',
          dataCy: 'translations',
          exact: true,
        },
      ],
    },
  ],
};
export default [dashboardRoutes];
