export const NextDayDeliveryIcon = ({
  ref,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  ref?: React.RefObject<SVGSVGElement>;
}) => (
  <svg
    width='43'
    height='29'
    viewBox='0 0 43 29'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.41797 4.625C13.0653 4.64561 16.1488 7.95263 17.1605 12.4872L9.41797 15.5189V4.625Z'
      fill='currentColor'
    />
    <path
      d='M17.1416 28.3423V26.0993H17.5506C17.6917 26.0993 17.8104 26.1268 17.9065 26.1816C18.0027 26.2364 18.08 26.3153 18.1384 26.4183C18.1968 26.5213 18.2388 26.6451 18.2644 26.7897C18.2899 26.9342 18.3027 27.0962 18.3027 27.2757C18.3027 27.4717 18.2845 27.6379 18.248 27.7741C18.2114 27.9103 18.1628 28.0208 18.1019 28.1055C18.041 28.1903 17.9717 28.2509 17.8938 28.2875C17.8159 28.324 17.7356 28.3423 17.6528 28.3423H17.1416ZM16.5684 25.4414V29.0002H17.693C17.8926 29.0002 18.066 28.9545 18.2133 28.8632C18.3605 28.7718 18.4835 28.6464 18.582 28.4869C18.6806 28.3273 18.7543 28.1379 18.803 27.9186C18.8516 27.6993 18.876 27.4601 18.876 27.2009C18.876 26.9052 18.8462 26.6476 18.7865 26.4283C18.7269 26.209 18.6441 26.0262 18.5382 25.88C18.4323 25.7338 18.307 25.6242 18.1621 25.5511C18.0173 25.478 17.8609 25.4414 17.693 25.4414H16.5684Z'
      fill='currentColor'
    />
    <path
      d='M19.2734 25.4414V29.0002H21.2524V28.3423H19.8467V27.47H21.11V26.8619H19.8467V26.0993H21.2232V25.4414H19.2734Z'
      fill='currentColor'
    />
    <path
      d='M21.6396 25.4414V29.0002H23.4835V28.3423H22.2129V25.4414H21.6396Z'
      fill='currentColor'
    />
    <path
      d='M24.3789 25.4414H23.8057V29.0002H24.3789V25.4414Z'
      fill='currentColor'
    />
    <path
      d='M26.0942 29.0002L26.9596 25.4414H26.3681L25.7802 27.9436H25.7729L25.1923 25.4414H24.6045L25.4479 29.0002H26.0942Z'
      fill='currentColor'
    />
    <path
      d='M27.1826 25.4414V29.0002H29.1616V28.3423H27.7559V27.47H29.0192V26.8619H27.7559V26.0993H29.1324V25.4414H27.1826Z'
      fill='currentColor'
    />
    <path
      d='M30.1221 27.0514V26.0495H30.7501C30.8816 26.0495 30.9801 26.0886 31.0459 26.1666C31.1116 26.2447 31.1445 26.3702 31.1445 26.543C31.1445 26.7224 31.1116 26.852 31.0459 26.9317C30.9801 27.0115 30.8816 27.0514 30.7501 27.0514H30.1221ZM29.5488 25.4414V29.0002H30.1221V27.6096H30.6953C30.839 27.6096 30.9424 27.6528 31.0057 27.7392C31.069 27.8256 31.1104 27.9618 31.1298 28.1479C31.1445 28.2908 31.1554 28.4403 31.1627 28.5965C31.17 28.7527 31.1895 28.8873 31.2211 29.0002H31.7944C31.7676 28.9504 31.7475 28.8897 31.7341 28.8183C31.7207 28.7469 31.711 28.6713 31.7049 28.5915C31.6988 28.5118 31.6946 28.4337 31.6921 28.3573C31.6897 28.2808 31.6873 28.2144 31.6848 28.1579C31.68 28.0682 31.6708 27.9785 31.6574 27.8887C31.6441 27.799 31.6228 27.7168 31.5935 27.642C31.5644 27.5672 31.5266 27.5024 31.4804 27.4476C31.4341 27.3928 31.3757 27.3521 31.3051 27.3255V27.3155C31.4512 27.2358 31.5564 27.1195 31.6209 26.9666C31.6855 26.8138 31.7177 26.6327 31.7177 26.4233C31.7177 26.2871 31.7001 26.16 31.6648 26.042C31.6295 25.9241 31.5783 25.8202 31.5114 25.7305C31.4445 25.6408 31.3641 25.5702 31.2704 25.5187C31.1767 25.4672 31.0714 25.4414 30.9546 25.4414H29.5488Z'
      fill='currentColor'
    />
    <path
      d='M32.7252 27.6146V29.0002H33.2985V27.6345L34.266 25.4414H33.6271L33.021 26.847L32.4112 25.4414H31.7686L32.7252 27.6146Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.9386 24.9024C14.224 24.6376 14.5148 24.8258 14.7087 25.2153C14.9027 25.6048 14.9268 26.0492 14.6415 26.314C13.1403 27.7068 11.3284 28.5213 9.37821 28.5213C4.20224 28.5213 0 22.7848 0 15.7191C0 8.65345 4.20224 2.91699 9.37821 2.91699C14.5542 2.91699 18.7564 8.65345 18.7564 15.7191C18.7564 16.1901 18.4648 16.4597 18.1197 16.4597C17.7747 16.4597 17.5061 16.1901 17.5061 15.7191C17.5061 9.59543 13.8641 4.62376 9.37821 4.62376C4.89229 4.62376 1.25029 9.59543 1.25029 15.7191C1.25029 21.8428 4.89229 26.8145 9.37821 26.8145C11.068 26.8145 12.638 26.1092 13.9386 24.9024Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.92307 2.56015C6.40554 2.56015 5.98535 1.98657 5.98535 1.28007C5.98535 0.573575 6.40554 0 6.92307 0H11.6534C12.1709 0 12.5911 0.573575 12.5911 1.28007C12.5911 1.98657 12.1709 2.56015 11.6534 2.56015H6.92307Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.3455 5.0847C15.0543 4.65045 15.0761 3.97513 15.3943 3.57759C15.7123 3.18003 16.2071 3.20981 16.4983 3.64406L19.0945 7.5153C19.3857 7.94956 19.3639 8.62488 19.0458 9.02242C18.7277 9.41998 18.233 9.3902 17.9418 8.95594L15.3455 5.0847Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.5558 8.26956C13.7273 8.00497 14.0238 7.98019 14.2177 8.21426C14.4115 8.4483 14.4296 8.85312 14.2582 9.11771L9.82288 15.962C9.65142 16.2266 9.35486 16.2514 9.16106 16.0173C8.96723 15.7833 8.94908 15.3785 9.12052 15.1139L13.5558 8.26956Z'
      fill='currentColor'
    />
    <path
      d='M9.42722 16.5747C9.85895 16.5747 10.2089 16.0969 10.2089 15.5075C10.2089 14.9182 9.85895 14.4404 9.42722 14.4404C8.99549 14.4404 8.64551 14.9182 8.64551 15.5075C8.64551 16.0969 8.99549 16.5747 9.42722 16.5747Z'
      fill='currentColor'
    />
    <path
      d='M11.645 23.9301L10.3808 19.233L9.64229 23.9301H8.6875L9.72879 17.3174H10.8033L12.0276 21.9337L12.7562 17.3174H13.711L12.6697 23.9301H11.645Z'
      fill='currentColor'
    />
    <path
      d='M17.5601 22.7412L17.3738 23.9301H13.6577L14.699 17.3174H18.2953L18.109 18.4883H15.5141L15.2945 19.8925H17.6765L17.4935 21.041H15.1115L14.8454 22.7412H17.5601Z'
      fill='currentColor'
    />
    <path
      d='M18.954 23.9301H17.763L19.8689 20.5609L18.8808 17.3174H20.1051L20.6407 19.4663L21.8783 17.3174H23.0626L21.0599 20.5071L22.1145 23.9301H20.8702L20.2914 21.678L18.954 23.9301Z'
      fill='currentColor'
    />
    <path
      d='M23.1624 18.4883L23.3487 17.3174H27.3209L27.1346 18.4883H25.6675L24.8125 23.9301H23.7812L24.6362 18.4883H23.1624Z'
      fill='currentColor'
    />
    <path
      d='M33.369 18.2999C33.4888 18.6289 33.5487 18.9848 33.5487 19.3676C33.5509 19.7504 33.5254 20.1153 33.4722 20.4622C33.3324 21.3415 33.084 22.0862 32.727 22.6964C32.2435 23.5188 31.6313 23.9301 30.8906 23.9301H28.778L29.8193 17.3174H31.9318C32.2335 17.3234 32.4786 17.3712 32.6671 17.4609C32.9864 17.6135 33.2204 17.8931 33.369 18.2999ZM32.4142 20.5564C32.5207 19.8835 32.524 19.3676 32.4242 19.0087C32.3244 18.6468 32.0416 18.4659 31.5759 18.4659H30.6311L29.9524 22.7816H30.8972C31.3807 22.7816 31.7688 22.4601 32.0616 21.8171C32.2213 21.4642 32.3388 21.0439 32.4142 20.5564Z'
      fill='currentColor'
    />
    <path
      d='M37.3046 17.3174L37.9999 23.9301H36.8888L36.7856 22.5708H34.9792L34.4269 23.9301H33.3557L36.1469 17.3174H37.3046ZM36.4763 18.8292L35.435 21.4313H36.6792L36.4763 18.8292Z'
      fill='currentColor'
    />
    <path
      d='M41.8391 17.3174H43.0001L40.7079 21.4447L40.3187 23.9301H39.2941L39.6833 21.4447L38.6387 17.3174H39.8463L40.4085 20.1975L41.8391 17.3174Z'
      fill='currentColor'
    />
  </svg>
);
