/* eslint-disable no-console */
import { Locale } from '@wyz/types';
import { Locale as LocaleModule } from 'date-fns';

export const DEFAULT_LOCALE: Locale = 'en-GB';

export const getLocaleModule = (locale: Locale): Promise<LocaleModule> => {
  return new Promise((resolve, reject) => {
    return loadDateFnsLocaleData(locale)
      .then((localeModule) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        resolve(localeModule.default);
      })
      .catch(() => {
        // retry with language only
        const language = locale.split('-')[0];
        return loadDateFnsLocaleData(language).then((localeModule) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          resolve(localeModule.default);
        });
      })
      .catch((error: Error) => {
        console.error(
          `Failed to load locale data for date-fns for ${locale}`,
          error,
        );
        reject(error);
      });
  });
};

function loadDateFnsLocaleData(locale: string) {
  return import(
    /* @vite-ignore */
    `https://cdn.jsdelivr.net/npm/date-fns@4.1.0/locale/${locale}/+esm`
  );
}

function getDisplayNames(locale: string) {
  return new Intl.DisplayNames([locale], {
    type: 'language',
    fallback: 'code',
    languageDisplay: 'standard',
    style: 'narrow',
  });
}
export const getHumanReadableLabel = ({
  locale,
  displayLocale,
  fallback,
}: {
  locale: Locale;
  displayLocale: Locale;
  fallback: string;
}) => {
  let displayNames = null;
  // Create an Intl.DisplayNames instance with the display locale
  try {
    displayNames = getDisplayNames(displayLocale);
  } catch (error) {
    try {
      // try the default
      displayNames = getDisplayNames(DEFAULT_LOCALE);
    } catch (error) {
      // return the fallback
      return fallback;
    }
  }
  // Get the human-readable label
  return displayNames.of(locale);
};
