import { get } from 'lodash-es';

import { Controller, useFormContext } from 'react-hook-form';
import { FormFeedback, FormGroup, Label } from '../index';

import { DateTimePicker, DateTimePickerProps } from '../date-time-picker';
import './date-range-field.scss';
import { DateFormatOptions } from '@wyz/utils';
type DateRangeFieldProps<TKeys extends string> = Pick<
  DateTimePickerProps,
  | 'name'
  | 'helperText'
  | 'label'
  | 'placeholderText'
  | 'required'
  | 'id'
  | 'showTimeSelect'
  | 'translations'
  | 'locale'
> & {
  translate: (key: TKeys) => string;
  dateFormat?: DateFormatOptions['format'];
};

function DateRangeField<TKeys extends string>({
  translate,
  ...props
}: DateRangeFieldProps<TKeys>) {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const errorMessage = get(errors, [props.name, 'message']) as TKeys;

  return (
    <FormGroup>
      <Label
        htmlFor={props.id || props.name}
        className={`${props.required ? 'required' : ''}`}
      >
        {props.label}
      </Label>
      <div>
        <Controller
          name={props.name}
          control={control}
          render={({ field }) => {
            return (
              <>
                <DateTimePicker<never, true>
                  {...props}
                  selectsRange={true}
                  selected={field.value?.[0]}
                  startDate={field.value?.[0]}
                  endDate={field.value?.[1]}
                  onChange={field.onChange}
                  monthsShown={2}
                />
              </>
            );
          }}
        />
      </div>
      {errorMessage ? (
        <FormFeedback invalid>{translate(errorMessage)}</FormFeedback>
      ) : props.helperText ? (
        <FormFeedback>{props.helperText}</FormFeedback>
      ) : null}
    </FormGroup>
  );
}

export default DateRangeField;
