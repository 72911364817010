import { Locale, RecordValues } from '@wyz/types';
import { isValid } from 'date-fns';

export const DEFAULT_LOCALE: Locale = 'en-GB';

export const DATE_FORMAT = {
  FULL_DATE_TIME: 'FULL_DATE_TIME', // 12/12/2021, 12:12
  DAY_MONTH_ONLY: 'DAY_MONTH_ONLY', // 12/12
  TIME_ONLY: 'TIME_ONLY', // 12:12
  RELATIVE_TIME: 'RELATIVE_TIME', // 2 days ago
  FULL_DATE: 'FULL_DATE', // 12/12/2021
} as const;

export const CUSTOM_DATE_FORMAT = {
  FULL_DATE: 'yyyy-MM-dd',
} as const;

type DateFormat = (typeof DATE_FORMAT)[keyof typeof DATE_FORMAT];
type CustomDateFormat =
  (typeof CUSTOM_DATE_FORMAT)[keyof typeof CUSTOM_DATE_FORMAT];
const getIntlFormatOption = (
  format: DateFormat,
): Intl.DateTimeFormatOptions | undefined => {
  switch (format) {
    case DATE_FORMAT.FULL_DATE_TIME:
      return { timeStyle: 'short', dateStyle: 'short' };
    case DATE_FORMAT.DAY_MONTH_ONLY:
      return { day: 'numeric', month: 'numeric' };
    case DATE_FORMAT.TIME_ONLY:
      return { timeStyle: 'short' };
    case DATE_FORMAT.FULL_DATE:
      return { dateStyle: 'short' };
    default:
      return undefined;
  }
};

export const getFormatPattern = (format: DateFormat): string => {
  switch (format) {
    case DATE_FORMAT.FULL_DATE_TIME:
      return 'dd/MM/yyyy, HH:mm';
    case DATE_FORMAT.DAY_MONTH_ONLY:
      return 'dd/MM';
    case DATE_FORMAT.TIME_ONLY:
      return 'HH:mm';
    case DATE_FORMAT.FULL_DATE:
      return 'dd/MM/yyyy';
    default:
      return '';
  }
};

type Format = RecordValues<typeof DATE_FORMAT>;
type Options = {
  format?: Format;
  customFormat?: CustomDateFormat;
  locale?: Locale;
  timezone?: string;
};
const DEFAULT_TIMEZONE = 'Europe/Paris';

function timeAgo(timestamp: Date, locale: Locale) {
  let value;
  const diff = (new Date().getTime() - timestamp.getTime()) / 1000;
  const minutes = Math.floor(diff / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

  if (years > 0) {
    value = rtf.format(0 - years, 'year');
  } else if (months > 0) {
    value = rtf.format(0 - months, 'month');
  } else if (days > 0) {
    value = rtf.format(0 - days, 'day');
  } else if (hours > 0) {
    value = rtf.format(0 - hours, 'hour');
  } else if (minutes > 0) {
    value = rtf.format(0 - minutes, 'minute');
  } else {
    value = rtf.format(0 - diff, 'second');
  }
  return value;
}

/**
 * Format a timestamp as a string
 * @param {Date} date
 *
 * @return {string}
 */
export const formatTimestamp = (
  date: Date,
  {
    format = DATE_FORMAT.FULL_DATE_TIME,
    locale = DEFAULT_LOCALE,
    timezone = DEFAULT_TIMEZONE,
    customFormat,
  }: Options = {
    format: DATE_FORMAT.FULL_DATE_TIME,
  },
): string => {
  if (customFormat) {
    return customFormatter(date, customFormat);
  }
  return format === DATE_FORMAT.RELATIVE_TIME
    ? timeAgo(date, locale)
    : Intl.DateTimeFormat(locale, {
        timeZone: timezone,
        ...getIntlFormatOption(format),
      }).format(new Date(date));
};

export type DateFormatOptions = NonNullable<
  Parameters<typeof formatTimestamp>[1]
>;

export const isValidDate = (date: string) => {
  const parsedDate = new Date(date);
  return isValid(parsedDate);
};
const customFormatter = (date: Date, format = CUSTOM_DATE_FORMAT.FULL_DATE) => {
  const formatter = new Intl.DateTimeFormat('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const parts = formatter.formatToParts(date).reduce(
    (acc, part) => {
      if (part.type !== 'literal') {
        acc[part.type] = part.value;
      }
      return acc;
    },
    {} as Record<string, string>,
  );

  return format
    .replace('yyyy', parts.year)
    .replace('MM', parts.month)
    .replace('dd', parts.day);
};
